<template>
	<div>
        <ShutterPanel />
    </div>
</template>


<script type="text/javascript">
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterDeplacement from '@/mixins/shutters-manager/Deplacement.js'

    export default {
		name: "Suggestion",
		mixins: [Shutter, ShutterDeplacement],
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ShutterPanel
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
				this.openSuggestionListe()
			}
		}
    }
</script>